import getConfig from "./getConfig";

const hostEnv = getConfig();

export const surveyEndpointMap = {
  "Mission Elevate": {
    CARS24: "mission-elevate",
    CARIOT: "mission-elevate-ca",
  },
  COI: {
    CARS24: "coi-survey",
    CARIOT: "coi-survey-cariot",
  },
  "COI Candidate": {
    CARS24: "coi-survey-candidate",
    CARIOT: "coi-candidate-cariot",
  },
  "IT Asset": {
    CARS24: "it-assets-insurance",
    CARIOT: "it-assets-cariot",
  },
  "Cultural Form": {
    CARS24: "cultural-form",
    CARIOT: "cultural-form-cariot",
  },
};

export const titleMap = {
  "/login": {
    title: "login",
  },
  "/": {
    title: "COI Declaration",
  },
  "/mission-elevate": {
    title: "Mission Elevate",
  },
  "/it-asset": {
    title: "IT Asset Insurance",
  },
  "/candidate": {
    title: "COI Declaration Candidate",
  },
  "/cultural-form": {
    title: "Cultural Form",
  },
  "/tech-session-form": {
    title: "Tech Session Form",
  },
};

export const LOGIN_CONFIG = {
  missionElevate: {
    title: "Mission Elevate",
    title2: "Pushing limits,",
    title3: "breaking barriers,",
    title4: "rising higher",
    para1: "Show your enthusiasm",
  },
  coi: {
    title: "Driving Efficiency,",
    title2: "Fueling Growth.",
    title3: "",
    title4: "",
    para1: `${hostEnv.entity}: COI Declaration`,
  },
  itAsset: {
    title: "Assets Protection Plan",
    title2: "Coverage for your cool gadgets",
    title3: "",
    title4: "",
    para1: "We are listening to you",
  },
  culturalForm: {
    title: "Assess the Fit",
    title2: "foster the Culture",
    title3: "",
    title4: "",
    para1: "",
  },
  dynamicForm: {
    title: "Technical Session Feedback",
    title2: "",
    title3: "",
    title4: "",
    para1: "",
  },
};

export const ELIGIBLE_EMPLOYEES = [
  "amit.duggal@cars24.com",
  "syed.nizami@cars24.com",
  "sankalp.jagtap@cars24.com",
  "santosh.kumar@cars24.com",
  "srikanth.m@cars24.com",
  "chandan.sanwal@cars24.com",
  "harish.v@cars24.com",
  "ashwini.v@cars24.com",
  "safaa.begum@cars24.com",
  "ravi.gawali@cars24.com",
  "shaikh.gulamrasul@cars24.com",
  "rajkumar.u@cars24.com",
  "wasim.khan@cars24.com",
  "yugal.mishra@cars24.com",
  "yogesh.kumar@cars24.com",
  "randheer.kumar@cars24.com",
  "sandip.dave@cars24.com",
  "raman.bairwa@cars24.com",
  "akbar.baig@cars24.com",
  "surya.anandan@cars24.com",
  "rampratap.gaur@cars24.com",
  "sonu.kumar3@cars24.com",
  "hema.1@cars24.com",
  "sameer.kumar@cars24.com",
  "nitn.shinde@cars24.com",
  "ajay.yadav@cars24.com",
  "shubhangini.bhoj@cars24.com",
  "muhammad.asraf@cars24.com",
  "pawnish.1@cars24.com",
  "sameer.magar@cars24.com",
  "chhater.singh@cars24.com",
  "amit.1@cars24.com",
  "yogesh.3@cars24.com",
  "sarvendra.1@cars24.com",
  "suresh.sharma@cars24.com",
  "pooja.kaushik@cars24.com",
  "bansi.lal@cars24.com",
  "ravinder.kumar1@cars24.com",
  "palackal.shajahan@cars24.com",
  "banala.srujana@cars24.com",
  "arvind.yadav1@cars24.com",
  "krishan.kumar1@cars24.com",
  "anuj.pathak@cars24.com",
  "shaik.waheed@cars24.com",
  "bablu.yadav@cars24.com",
  "ravinder.singh2@cars24.com",
  "vikas.bhosale@cars24.com",
  "nabakishore.behera@cars24.com",
  "manish.jaiswal@cars24.com",
  "rachit.grover@cars24.com",
  "kavita.mandal@cars24.com",
  "chinthapalli.rao@cars24.com",
  "bittu.kumar@cars24.com",
  "neha.negi@cars24.com",
  "nishant.2@cars24.com",
  "nihal.patel@cars24.com",
  "mandeep.negi@cars24.com",
  "sourabh.1@cars24.com",
  "aabid.shah@cars24.com",
  "ravinder.bisht@cars24.com",
  "nitin.gola@cars24.com",
  "anoop.yadav@cars24.com",
  "prem.bahadur@cars24.com",
  "abhivendra.singh@cars24.com",
  "satish.tiwari@cars24.com",
  "rajesh.sharma@cars24.com",
  "rajender.kaushal@cars24.com",
  "sujitha.n@cars24.com",
  "vinay.3@cars24.com",
  "deepak.sharma7@cars24.com",
  "bvn.rao@cars24.com",
  "arjun.shukla@cars24.com",
  "gohil.kumar@cars24.com",
  "sameer.mevada@cars24.com",
  "pritpal.singh1@cars24.com",
  "naveen.parsha@cars24.com",
  "sree.kumar@cars24.com",
  "vannada.goud@cars24.com",
  "rahul.bodke@cars24.com",
  "kiran.chichate@cars24.com",
  "vinod.khatik@cars24.com",
  "vipin.2@cars24.com",
  "deepak.gupta3@cars24.com",
  "Old_raman.luthara@cars24.com",
  "arjun.kumar1@cars24.com",
  "pradeep.gautam@cars24.com",
  "roshan.chandra@cars24.com",
  "harish.kumar3@cars24.com",
  "honey.singh1@cars24.com",
  "ajith.krishnan@cars24.com",
  "haider.ali1@cars24.com",
  "disha.chalke@cars24.com",
  "sunny.manocha@cars24.com",
  "paramjeet.singh@cars24.com",
  "anant.arolkar@cars24.com",
  "aman.singh1@cars24.com",
  "vinod.pippl@cars24.com",
  "ankit.sheth1@cars24.com",
  "pradeep.kumar12@cars24.com",
  "sanket.kumar@cars24.com",
  "ankit.kumar6@cars24.com",
  "m.aravind@cars24.com",
  "azim.1@cars24.com",
  "nitesh.sharma@cars24.com",
  "mohammed.sami@cars24.com",
  "santosh.k1@cars24.com",
  "ganesh.kadam@cars24.com",
  "than.singhd@cars24.com",
  "vineeth.babu@cars24.com",
  "jasmeet.singh@cars24.com",
  "aswin.1@cars24.com",
  "satyambhai.devmurari@cars24.com",
  "firoz.momin@cars24.com",
  "shaik.irfan@cars24.com",
  "sanjay.yadav1@cars24.com",
  "ganesh.narkhede@cars24.com",
  "yogendra.pal@cars24.com",
  "muskan.aggarwal1@cars24.com",
  "sailesh.s@cars24.com",
  "sonia.pandit@cars24.com",
  "sunil.chauhan@cars24.com",
  "gopi.1@cars24.com",
  "mitesh.patel@cars24.com",
  "deepan.s@cars24.com",
  "naveen.2@cars24.com",
  "ansari.ishaque@cars24.com",
  "tarun.kumar5@cars24.com",
  "sarika.kataria@cars24.com",
  "yogesh.saini@cars24.com",
  "wadhavane.vishwas@cars24.com",
  "manish.gupta1@cars24.com",
  "v.hariharan@cars24.com",
  "prince.kumar3@cars24.com",
  "dinesh.saini1@cars24.com",
  "mahmmad.pathan@cars24.com",
  "nitesh.bhalla@cars24.com",
  "ankita.mohanty@cars24.com",
  "puduri.kumar@cars24.com",
  "ashok.kumar5@cars24.com",
  "jaibir.1@cars24.com",
  "nishant.bhardwaj@cars24.com",
  "robin.kumar@cars24.com",
  "vikas.6@cars24.com",
  "arnab.mitra@cars24.com",
  "jyothish.g@cars24.com",
  "saulab.1@cars24.com",
  "pravesh.kr@cars24.com",
  "naghesh.sharma@cars24.com",
  "abhishek.lanjewar@cars24.com",
  "ramesh.kr1@cars24.com",
  "prithika.salian@cars24.com",
  "deepak.kumar@cars24.com",
  "sachin.shelar@cars24.com",
  "samarendra.roy@cars24.com",
  "sakshi.rajput@cars24.com",
  "thiruvakadu.santhosh@cars24.com",
  "saddam.hussain1@cars24.com",
  "sherkhane.divya@cars24.com",
  "swapnil.jadhav2@cars24.com",
  "rohit.verma2@cars24.com",
  "nishad.n@cars24.com",
  "balu.a@cars24.com",
  "hari.singh1@cars24.com",
  "sameer.2@cars24.com",
  "ashutosh.upadhyay@cars24.com",
  "krishna.sahu@cars24.com",
  "pritam.sain@cars24.com",
  "akash.dhawad@cars24.com",
  "raj.kr@cars24.com",
  "jasvinder.bedi@cars24.com",
  "nimmi.gupta@cars24.com",
  "nishant.singh@cars24.com",
  "deepak.kamdar@cars24.com",
  "deepak.shranagat@cars24.com",
  "arun.g1@cars24.com",
  "rahul.sahu@cars24.com",
  "shubham.sharma5@cars24.com",
  "diksha.guglani@cars24.com",
  "mo.shaikh@cars24.com",
  "lalit.kr@cars24.com",
  "chahat.middha1@cars24.com",
  "amit.gupta3@cars24.com",
  "suraj.singh@cars24.com",
  "kamini.1@cars24.com",
  "ajay.kr1@cars24.com",
  "pooja.mishra@cars24.com",
  "sharad.kr@cars24.com",
  "atul.kr@cars24.com",
  "charu.bhatia@cars24.com",
  "nidhi.dahayat@cars24.com",
  "yashpal.singh@cars24.com",
  "pratik.jagtap@cars24.com",
  "pradeep.kr@cars24.com",
  "sunil.dutt@cars24.com",
  "sashidhara.g@cars24.com",
  "avinash.3@cars24.com",
  "bhavesh.bodana@cars24.com",
  "divya.1@cars24.com",
  "gopal.n@cars24.com",
  "sushil.chander@cars24.com",
  "sumit.jakhar@cars24.com",
  "ankit.7@cars24.com",
  "amit.kr6@cars24.com",
  "deepak.gaur@cars24.com",
  "manish.7@cars24.com",
  "prashant.srivastava@cars24.com",
  "koushik.debnath@cars24.com",
  "fahad.hakim@cars24.com",
  "kapil.perjapat@cars24.com",
  "anjali.giri@cars24.com",
  "ashish.gaur1@cars24.com",
  "v.preami@cars24.com",
  "amol.begar@cars24.com",
  "ashutosh.chauhan@cars24.com",
  "nitish.kr1@cars24.com",
  "zunaid.ali@cars24.com",
  "shivrajbhai.dhadhal1@cars24.com",
  "parminder.1@cars24.com",
  "sagar.kr1@cars24.com",
  "rishabh.sharma3@cars24.com",
  "surendra.gautam@cars24.com",
  "manish.singh3@cars24.com",
  "shaikh.asgar@cars24.com",
  "kishan.poddar@cars24.com",
  "md.najmuddin@cars24.com",
  "firoj.sk@cars24.com",
  "haresh.kushwah@cars24.com",
  "mohd.shaikh@cars24.com",
  "gagan.sharma@cars24.com",
  "mohammed.imran1@cars24.com",
  "thumar.rameshbhai@cars24.com",
  "pankhaniya.bhimjibhai@cars24.com",
  "aadil.khan1@cars24.com",
  "prince.kr@cars24.com",
  "dayana.anthonisamy@cars24.com",
  "naresh.kr1@cars24.com",
  "ram.kr@cars24.com",
  "sandeep.singh8@cars24.com",
  "sumit.vyas@cars24.com",
  "ricky.dhingra@cars24.com",
  "gopal.bhusapure@cars24.com",
  "vishal.dhanewad@cars24.com",
  "kesara.pitamber@cars24.com",
  "sunny.kr2@cars24.com",
  "rakesh.madakam2@cars24.com",
  "amrith.kd@cars24.com",
  "charley.srivastava@cars24.com",
  "kysar.m@cars24.com",
  "george.gawali@cars24.com",
  "karan.chaurasia@cars24.com",
  "Imtiyaz.Haider@cars24.com",
  "Neeta.Kumari@cars24.com",
  "satpal.1@cars24.com",
  "lalit.5@cars24.com",
  "ganta.prudhvikar@cars24.com",
  "rahul.bansal@cars24.com",
  "naveen.mandal@cars24.com",
  "siddu.r@cars24.com",
  "abhishek.jain2@cars24.com",
  "sagar.patil@cars24.com",
  "deepa.h@cars24.com",
  "rohit.saini1@cars24.com",
  "chandnee.khatoon@cars24.com",
  "naumankhan.kadiwala@cars24.com",
  "farsana.1@cars24.com",
  "jitendra.kr2@cars24.com",
  "akash.4@cars24.com",
  "gourav.roy@cars24.com",
  "pathan.kasamkhan@cars24.com",
  "suraj.yadav@cars24.com",
  "lekhraj.1@cars24.com",
  "hemant.kr1@cars24.com",
  "niti.singh@cars24.com",
  "mohit.rohilla@cars24.com",
  "swagini.kendale@cars24.com",
  "raja.sanam@cars24.com",
  "shubham.kr1@cars24.com",
  "mansoor.rana@cars24.com",
  "gaurav.sindhwal@cars24.com",
  "praveen.j@cars24.com",
  "indrapal.1@cars24.com",
  "aashish.1@cars24.com",
  "mitesh.singh@cars24.com",
  "sheikh.raza1@cars24.com",
  "rohit.kamble1@cars24.com",
  "nitish.2@cars24.com",
  "abhishek.prajapati@cars24.com",
  "mohd.imran2@cars24.com",
  "lalit.singh2@cars24.com",
  "aadil.khan2@cars24.com",
  "raj.soni@cars24.com",
  "ravinder.sandhu@cars24.com",
  "ashish.kr2@cars24.com",
  "patel.rameshchandra@cars24.com",
  "prashant.kumar4@cars24.com",
  "deshani.bhai@cars24.com",
  "deepak.jaiswar@cars24.com",
  "monnappa.h@cars24.com",
  "himanshu.malik@cars24.com",
  "sonu.hazra@cars24.com",
  "sudip.prasad@cars24.com",
  "ajay.kanojia@cars24.com",
  "pradeep.pandey@cars24.com",
  "mazhar.ali@cars24.com",
  "rakesh.sharma@cars24.com",
  "karan.mainra@cars24.com",
  "balwinder.arora@cars24.com",
  "Sunny.2@cars24.com",
  "natarajan.r@cars24.com",
  "vinothkumar.s@cars24.com",
  "senthil.b@cars24.com",
  "sankar.s@cars24.com",
  "balveer.banshiwal@cars24.com",
  "manish.pandey1@cars24.com",
  "alen.joseph@cars24.com",
  "yogesh.kr01@cars24.com",
  "srinivasan.p@cars24.com",
  "arshad.khan1@cars24.com",
  "sugumar.1@cars24.com",
  "jeyakumar.perumal@cars24.com",
  "shivendra.singh6@cars24.com",
  "atul.karande@cars24.com",
  "priya.aggarwal@cars24.com",
  "vimal.2@cars24.com",
  "aamir.khan01@cars24.com",
  "bhasker.1@cars24.com",
  "k.shenoy@cars24.com",
  "shreyas.s@cars24.com",
  "shatrughan.1@cars24.com",
  "suraj.ghanekar@cars24.com",
  "mohammedusaif.mansuri@cars24.com",
  "dhananjay.rankawat@cars24.com",
  "alok.kumar4@cars24.com",
  "asha.1@cars24.com",
  "abhishek.tyagi1@cars24.com",
  "r.kumar3@cars24.com",
  "ayushi.chauhan@cars24.com",
  "aafreen.siddiqui@cars24.com",
  "ashish.kr02@cars24.com",
  "dalli.sachdeva@cars24.com",
  "yalala.varma@cars24.com",
  "m.gopal@cars24.com",
  "shashi.sharma@cars24.com",
  "pratosh.naik@cars24.com",
  "shashidutt.mishra@cars24.com",
  "vijay.shriwas@cars24.com",
  "deepak.kr08@cars24.com",
  "milton.dias@cars24.com",
  "rajeev.ambedkar@cars24.com",
  "manojkumar.b@cars24.com",
  "ashish.kharat@cars24.com",
  "mohit.gahlot@cars24.com",
  "sweta.kumari@cars24.com",
  "ravi.maurya3@cars24.com",
  "varun.1@cars24.com",
  "kotte.suresh@cars24.com",
  "vigneshwaran.1@cars24.com",
  "prithiviraj.d1@cars24.com",
  "krishan.kumar4@cars24.com",
  "sudhir.1@cars24.com",
  "harish.6@cars24.com",
  "sumit.kr02@cars24.com",
  "rajendra.kumar@cars24.com",
  "devanshu.srivastava@cars24.com",
  "vivek.bhandari@cars24.com",
  "akshay.jadhavrao@cars24.com",
  "ajay.pal@cars24.com",
  "shailendra.chand@cars24.com",
  "jayaraj.1@cars24.com",
  "mohammed.uddin@cars24.com",
  "love.arora@cars24.com",
  "Prashant.Inamdar@Cars24.Com",
  "Shiv.Kr01@Cars24.Com",
  "Vivek.Vishwakarma2@Cars24.Com",
  "anurag.yadav@cars24.com",
  "Dheeraj.Kr01@Cars24.Com",
  "rahul.gahlot@cars24.com",
  "Abhishek.Sharma02@cars24.com",
  "Kalaivani.M@cars24.com",
  "arumugam.lakshmanan@cars24.com",
  "balaprasad.irudayaraj@cars24.com",
  "Gaurav.Verma6@cars24.com",
  "Mahesh.Ghagare@cars24.com",
  "Amit.Hedaoo1@cars24.com",
  "Ranjitsinh.Patil@cars24.com",
  "Dushyant.Rana@cars24.com",
  "Tejas.Jangam1@cars24.com",
  "manimaran.pandurangan@cars24.com",
  "Manish.rawat@cars24.com",
  "Jitendra.Kr02@cars24.com",
  "Silambarasan.Kaliyamoorthy@cars24.com",
  "Preksha.S@cars24.com",
  "M.Saravanan1@Cars24.Com",
  "Shiva.V@Cars24.Com",
  "Shashanka.Boruah@Cars24.Com",
  "Gaurav.Kr04@Cars24.Com",
  "Varun.Yadav2@Cars24.Com",
  "Zameel.2@Cars24.Com",
  "Mehta.Bimalkumar@Cars24.Com",
  "Mahmooder.Rahman@cars24.com",
  "Himanshu.Sharma5@Cars24.Com",
  "Lalit.Mishra2@Cars24.Com",
  "Dipanjali.Panda@Cars24.Com",
  "Anil.Goswami@Cars24.Com",
  "R.Anitha@Cars24.Com",
  "Ganesh.Rathod@Cars24.Com",
  "gitesh.jamghare@cars24.com",
  "Rahul.Nishad@Cars24.Com",
  "Ashish.Raturi@Cars24.Com",
  "Dasari.Kishore@Cars24.Com",
  "danish.shaikh@cars24.com",
  "Juber.Ali@cars24.com",
  "Prakash.Satsangi@cars24.com",
  "roshan.singh1@cars24.com",
  "Farhan.Shaikh2@cars24.com",
  "zishan.ali1@cars24.com",
  "puneet.tyagi@cars24.com",
  "mithilesh.yadav1@cars24.com",
  "bhuwan.2@cars24.com",
  "soniya.sharma@cars24.com",
  "sachin.singh@cars24.com",
  "sumit.kr1@cars24.com",
  "vicky.2@cars24.com",
  "tarun.atode@cars24.com",
  "mohd.faisal1@cars24.com",
  "anil.5@cars24.com",
  "shahnawaz.khan1@cars24.com",
  "manendra.singh1@cars24.com",
  "md.nizam1@cars24.com",
  "anjana.sejwal@cars24.com",
  "m.deeparani@cars24.com",
  "rimpy.jaswal1@cars24.com",
  "amarjit.kaur@cars24.com",
  "ajay.rajak@cars24.com",
  "ayush.saxena4@cars24.com",
  "priyam.srivastava@cars24.com",
  "ayush.gaur@cars24.com",
  "vishal.gupta2@cars24.com",
  "anil.rana1@cars24.com",
  "akanksha.chauhan@cars24.com",
  "kesireddy.reddy@cars24.com",
  "shaikh.bafatibhai@cars24.com",
  "Sanjeev.3@cars24.com",
  "saurabh.tiwari2@cars24.com",
  "jyoti.singh1@cars24.com",
  "deepak.kr1@cars24.com",
  "Chandrappa.1@cars24.com",
  "pravesh.2@cars24.com",
  "amit.arora@cars24.com",
  "binu.kuruvila@cars24.com",
  "gautam.nagar@cars24.com",
  "denson.david1@cars24.com",
  "varsha.dixit@cars24.com",
  "sohil.malek@cars24.com",
  "Sajid.3@cars24.com",
  "gireesh.sharma1@cars24.com",
  "sandeep.kuntal1@cars24.com",
  "rajeev.mishra@cars24.com",
  "yuneeb.mulla1@cars24.com",
  "kiran.waje1@cars24.com",
  "surya.singh2@cars24.com",
  "Srikanth.1@cars24.com",
  "vikrant.singh1@cars24.com",
  "ram.aggarwal@cars24.com",
  "siddhartha.pal@cars24.com",
  "pooja.sain@cars24.com",
  "pathan.shahabajkhan@cars24.com",
  "vipulbhai.patel@cars24.com",
  "anshika.sharma1@cars24.com",
  "shreya.2@cars24.com",
  "tabrez.baig@cars24.com",
  "ifthqar.e@cars24.com",
  "jatin.verma@cars24.com",
  "macha.goud@cars24.com",
  "gaurav.shewale1@cars24.com",
  "vishal.a@cars24.com",
  "vinay.kr2@cars24.com",
  "Priyanka.8@cars24.com",
  "chandan.singh@cars24.com",
  "kiran.rani@cars24.com",
  "janeshT.m@cars24.com",
  "ankur.singh2@cars24.com",
  "suresh.mathiyalagan1@cars24.com",
  "mojiz.rizvi1@cars24.com",
  "rameez.shaikh3@cars24.com",
  "sharad.karan@cars24.com",
  "veerasankar.selvakumar@cars24.com",
  "aljin.alex1@cars24.com",
  "m.farzana@cars24.com",
  "sachin.singh1@cars24.com",
  "jayesh.patil@cars24.com",
  "bhavatharani.veeramani@cars24.com",
  "mansuri.gaffarhusen@cars24.com",
  "preeti.kashyap1@cars24.com",
  "mansuri.safwan@cars24.com",
  "sushmita.madival@cars24.com",
  "raj.bariya@cars24.com",
  "shabeeb.kidwai1@cars24.com",
  "vikas.kr@cars24.com",
  "ASHISH.7@cars24.com",
  "apoorva.1@cars24.com",
  "pramod.pujar@cars24.com",
  "amresh.k@cars24.com",
  "amit.yadav2@cars24.com",
  "abdul.khan3@cars24.com",
  "VIJAY.LODHA@cars24.com",
  "sushil.singh@cars24.com",
  "pal.kumar@cars24.com",
  "ANISH.LAL1@cars24.com",
  "rahul.gupta5@cars24.com",
  "dharmendra.1@cars24.com",
  "PRIYANKA.12@cars24.com",
  "KAJAL.RAWAT@cars24.com",
  "suresh.sankhla@cars24.com",
  "bibin.joseph@cars24.com",
  "km.Suman@cars24.com",
  "maskuri.karthik1@cars24.com",
  "tejas.s@cars24.com",
  "ravi.gupta3@cars24.com",
  "vishal.singh9@cars24.com",
  "valay.chavhan@cars24.com",
  "dhruv.kumar1@cars24.com",
  "chakravati.karan@cars24.com",
  "shamim.ahmed2@cars24.com",
  "munshi.salimbhai@cars24.com",
  "remya.krishnan@cars24.com",
  "karn.dev@cars24.com",
  "rahul.bhatia@cars24.com",
  "abdul.rizvi@cars24.com",
  "omkar.mahendru1@cars24.com",
  "rahul.bakshi@cars24.com",
  "m.shivanarayanan@cars24.com",
  "pradeep.yadav2@cars24.com",
  "sumit.rawat@cars24.com",
  "n.mohideen1@cars24.com",
  "sankar.narayanan@cars24.com",
  "sandeep.kr3@cars24.com",
  "prajapati.dashrathbhai@cars24.com",
  "annu.kumari@cars24.com",
  "gorige.gangaraj@cars24.com",
  "ajay.rawat1@cars24.com",
  "raghunandan.s1@cars24.com",
  "vishal.soni@cars24.com",
  "harichandan.ravindra@cars24.com",
  "tushar.baroth1@cars24.com",
  "mohammed.sajeed@cars24.com",
  "patel.harshadbhai@cars24.com",
  "Sonia.4@cars24.com",
  "magesh.subramani@cars24.com",
  "vinaya.sharma@cars24.com",
  "makvana.dikshit@cars24.com",
  "MUSKAN.1@cars24.com",
  "yuvaraj.d@cars24.com",
  "vishnu.s3@cars24.com",
  "ranju.sahare@cars24.com",
  "prathmesh.tawade@cars24.com",
  "patel.manubhai@cars24.com",
  "prasad.potdar@cars24.com",
  "ashokkumar.savaliya@cars24.com",
  "ayush.nigam1@cars24.com",
  "sanu.biswas@cars24.com",
  "priyanka.13@cars24.com",
  "neha.sengar@cars24.com",
  "binay.bharali@cars24.com",
  "kuldeep.jangir@cars24.com",
  "shaikh.ahmed5@cars24.com",
  "himanshu.8@cars24.com",
  "prajapati.kajal@cars24.com",
  "nilesh.abbad@cars24.com",
  "mohit.k1@cars24.com",
  "dhanush.gowda@cars24.com",
  "pankaj.chauhan@cars24.com",
  "ashok.bujel@cars24.com",
  "mohammad.hussain1@cars24.com",
  "raghavendra.shet@cars24.com",
  "bhanu.singh@cars24.com",
  "soundarya.1@cars24.com",
  "chetan.bornare@cars24.com",
  "itishree.sahu@cars24.com",
  "swati.sharma2@cars24.com",
  "aparna.rai@cars24.com",
  "deepak.khambra@cars24.com",
  "manvendra.samrat@cars24.com",
  "ankur.singh3@cars24.com",
  "vikas.kamble@cars24.com",
  "vikramjeet.singh1@cars24.com",
  "jatin.kumar2@cars24.com",
  "jeevitha.kumar@cars24.com",
  "mo.khan@cars24.com",
  "santhosh.n1@cars24.com",
  "M.AMARNADHNAIDU@cars24.com",
  "ankit.jaiswar@cars24.com",
  "diksha.srivastava@cars24.com",
  "Saburkhan.Pathan@cars24.com",
  "aarti.2@cars24.com",
  "shubham.kr2@cars24.com",
  "parmar.somabhai@cars24.com",
  "Vaibhav.Ganthale@cars24.com",
  "hrishikesh.saikia@cars24.com",
  "prajapati.kinjal@cars24.com",
  "PAL.ASHISH@cars24.com",
  "shaikh.mahammad@cars24.com",
  "shivam.k@cars24.com",
  "vikas.singh9@cars24.com",
  "zeeshan.ahmad@cars24.com",
  "ronak.kumar@cars24.com",
  "meer.kehkashan1@cars24.com",
  "ratan.kumar2@cars24.com",
  "neeraj.10@cars24.com",
  "neha.baghel@cars24.com",
  "vimal.mishra@cars24.com",
  "Hemant.Chhakkar@cars24.com",
  "Abhishek.Saini@cars24.com",
  "nicky.kumari@cars24.com",
  "vishwakarma.indrajit@cars24.com",
  "dilnaz.shergar@cars24.com",
  "reena.2@cars24.com",
  "gaurav.k2@cars24.com",
  "diptee.jadhav1@cars24.com",
  "dulapalli.bhavana@cars24.com",
  "vishnu.rao@cars24.com",
  "anju.p@cars24.com",
  "sonam.3@cars24.com",
  "KIRAN.KHOMANE@cars24.com",
  "SAGAR.NIKAM@cars24.com",
  "DESAI.VISABHAI@cars24.com",
  "kakaraparthi.satya@cars24.com",
  "RUPESH.AMBELKAR@cars24.com",
  "ABDUL.KADIR2@cars24.com",
  "KUMARESH.RAVI@cars24.com",
  "preeti.3@cars24.com",
  "deepak.tiwari2@cars24.com",
  "neha.tanwar@cars24.com",
  "tirupati.narlawar@cars24.com",
  "deepak.13@cars24.com",
  "leena.1@cars24.com",
  "utkarsh.shukla2@cars24.com",
  "surjeet.5@cars24.com",
  "rahul.tara@cars24.com",
  "sagnik.dasgupta1@cars24.com",
  "ranjitha.p@cars24.com",
  "neha.rai@cars24.com",
  "mangalagiri.tharunika@cars24.com",
  "ranjan.rajak@cars24.com",
  "prince.bijalwan@cars24.com",
  "surbhi.agarwal@cars24.com",
  "sonika.4@cars24.com",
  "nagaraj.melamantri@cars24.com",
  "jyoti.gupta@cars24.com",
  "naresh.singh@cars24.com",
  "surinder.singh@cars24.com",
  "anand.parkash@cars24.com",
  "sk.sahabuddin@cars24.com",
  "babita.chaudhary1@cars24.com",
  "jainab.bano@cars24.com",
  "sanjana.verma1@cars24.com",
  "manoj.ubarhande@cars24.com",
  "Niranjan.K@cars24.com",
  "Sharique.Eqbal1@Cars24.Com",
  "lavakush.chauhan@cars24.com",
  "anand.sharma1@cars24.com",
  "madhuchhanda.behera@cars24.com",
  "nadeem.nazar1@cars24.com",
  "rajesh.jadhav@cars24.com",
  "harish.2@cars24.com",
  "akshay.chari@cars24.com",
  "md.salim@cars24.com",
  "komal.makwana@cars24.com",
  "aman.kesharwani@cars24.com",
  "hemant.8@cars24.com",
  "arunaditya.tomar1@cars24.com",
  "adarsh1.behera@cars24.com",
  "old_ashwin.dube@cariotauto.com",
  "bharadiya.kaushik@cariotauto.com",
  "neelesh.sharma@cariotauto.com",
  "deepak.sharma03@cariotauto.com",
  "rafi.kidoai@cariotauto.com",
  "dibyendu.das@cariotauto.com",
  "alpana.saha@cariotauto.com",
  "manish.kumar@cariotauto.com",
  "bipin.pandey1@cariotauto.com",
  "amson.b@cariotauto.com",
  "rehan.pathan1@cariotauto.com",
  "avinash.kamble@cariotauto.com",
  "pankaj.patil@cariotauto.com",
  "rahul.majde@cariotauto.com",
  "palivela.karthik@cariotauto.com",
  "rajeshkannan.s@cariotauto.com",
  "karnagari.reddy@cariotauto.com",
  "raghul.t@cariotauto.com",
  "m.vinothkumar@cariotauto.com",
  "shamseer.m@cariotauto.com",
  "hardik.parker@cariotauto.com",
  "p.kumar@cariotauto.com",
  "ayush.singh@cariotauto.com",
  "pradeep.1@cariotauto.com",
  "abinav.1@cariotauto.com",
  "deepak.kumar@cariotauto.com",
  "mukesh.kumar@cariotauto.com",
  "rajesh.tiwari@cariotauto.com",
  "mohd.kasim@cariotauto.com",
  "nitesh.pathak@cariotauto.com",
  "dinesh.kumar2@cariotauto.com",
  "raju.pandey@cariotauto.com",
  "santosh.kumar@cariotauto.com",
  "amarjeet.singh@cariotauto.com",
  "deepak.kushwaha@cariotauto.com",
  "chavva.kumar@cariotauto.com",
  "ravi.pathak@cariotauto.com",
  "shaik.fareed@cariotauto.com",
  "bharath.p@cariotauto.com",
  "syed.ahmed@cariotauto.com",
  "thakkar.kumar@cariotauto.com",
  "sandeep.parmar@cariotauto.com",
  "vankdavath.radhika@cariotauto.com",
  "thirumurugan.patrose@cariotauto.com",
  "vishnu.1@cariotauto.com",
  "rineesh.baiju@cariotauto.com",
  "shankar.digamabar@cariotauto.com",
  "s.ramkumar2@cariotauto.com",
  "jitender.sharma@cariotauto.com",
  "m.hemachandran@cariotauto.com",
  "vijendra.singh@cariotauto.com",
  "sumit.kumar1@cariotauto.com",
  "divyam.1@cariotauto.com",
  "lokesh.sharma@cariotauto.com",
  "v.kamaraj@cariotauto.com",
  "barot.rohitbhai@cariotauto.com",
  "rahul.verma@cariotauto.com",
  "ankit.karan@cariotauto.com",
  "satish.kunwar@cariotauto.com",
  "meetk.trivedi@cariotauto.com",
  "sangram.patil@cariotauto.com",
  "sharda.singh@cariotauto.com",
  "shivkumar.malhotra@cariotauto.com",
  "badur.reddy@cariotauto.com",
  "suman.ghosh@cariotauto.com",
  "mudit.tiwari@cariotauto.com",
  "adil.sharif@cariotauto.com",
  "salmankhan.pathan@cariotauto.com",
  "abhishek.swarnkar@cariotauto.com",
  "bhawna.fulara@cariotauto.com",
  "maniyendra.singh@cariotauto.com",
  "avi.malhotra@cariotauto.com",
  "rishi.nayyar@cariotauto.com",
  "arvind.singh@cariotauto.com",
  "rahul.shukla@cariotauto.com",
  "sumitkumar.thakur@cariotauto.com",
  "kaushal.parekh@cariotauto.com",
  "vamasi.krishna@cariotauto.com",
  "NAVEENKUMAR.N@cariotauto.com",
  "anamika.srivastava@cariotauto.com",
  "gokul.kumarasamy@cariotauto.com",
  "vineet.sharma@cariotauto.com",
  "sajan.varghese@cariotauto.com",
  "chauhan.ajitsinh@cariotauto.com",
  "shubham.baghele@cariotauto.com",
  "sandeep.hait@cariotauto.com",
  "muzammil.haidary@cariotauto.com",
  "avinash.jha@cariotauto.com",
  "ajay.ganvir@cariotauto.com",
  "G.Alim@cariotauto.com",
  "mohammad.alam2@cariotauto.com",
  "Sharad.Kadam@cariotauto.com",
  "Ishan.Singh@cariotauto.com",
  "gautam.sethi@cariotauto.com",
  "meer.singh@cariotauto.com",
  "old_arun.punia@cariotauto.com",
  "naman.bhatia@cars24.com",
  "vikram@cars24.com",
  "nitin.khera@cars24.com",
  "amit1.singh@cars24.com",
  "manmeet.2@cars24.com",
  "rahul.sharma11@cars24.com",
  "prachi.s@cars24.com",
  "atul1.kumar@cars24.com",
  "test.1@cariotauto.com",
  "bhoomika.singh@cars24.com",
  "vivek.naiwal@cars24.com",
  "wasim.k2@cars24.com",
  "satakshi.jaiswal@cars24.com",
  "swapnil.mishra@cars24.com",
  "akshay2.kumar@cars24.com",
  "ishita.1@cars24.com",
  "vidit.vishal@cars24.com",
  "shivanshu.makkar@cariotauto.com",
  "bikesh.singh@cariotauto.com",
  "vikas.solanki1@cars24.com",
  "ruchit@cars24.com",
  "gajendra.jangid@cars24.com",
  "pranati.tandon@cars24.com",
  "asmita.raj@cars24.com",
  "arvind.modi@cars24.com",
  "manoj1.yadav@cars24.com",
  "prachi.s@cars24.com",
  "deepak1.gupta@cars24.com",
  "manuj.rastogi@cars24.com",
  "raunak.singh@cars24.com",
  "aadil.khan2@cars24.com",
];
